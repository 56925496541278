$min-width: 500px;
$max-width: 500px;

.profileInfo {
    min-width: $min-width;
    max-width: $max-width;
}

.inputs {
    min-width: $min-width;
    max-width: $max-width;

    label {
        min-width: 130px;
        justify-content: flex-start;

        &[aria-required="true"] {
            &:after {
                content: "*";
                color: #ff0000;
                font-weight: bold;
                margin-left: 4px;
            }
        }
    }
}
