$min-width: 500px;
$max-width: 500px;

.spinnerContainer {
    width: 100%;
    height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.filterInputs {
    min-width: $min-width;
    max-width: $max-width;

    label {
        min-width: 140px;
        justify-content: flex-start;

        &[aria-required="true"] {
            &:after {
                content: "*";
                color: #ff0000;
                font-weight: bold;
                margin-left: 4px;
            }
        }
    }
}

.tmsUsersTableContainer {
    width: 100%;

    margin-top: 32px;

    :global {
        .p-datatable {
            width: 100%;
        }

        .p-datatable-header {
            width: 100%;
        }
    }
}
