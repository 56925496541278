.tmsEventsDropDown {
    min-width: 300px;
}

.tmsTicketsTableContainer {
    width: 100%;

    margin-top: 32px;

    :global {
        .p-datatable {
            width: 100%;
        }

        .p-datatable-header {
            width: 100%;
        }

        .p-multiselect-items-label {
            display: flex;
            flex-wrap: wrap;
        }

        .p-multiselect-token {
            margin-bottom: .25rem;
            margin-top: .25rem;
        }
    }

    .tmsData {
        overflow-x: auto;

        .tmsDataRow {
            span {
                opacity: .5;
            }

            &:not(:last-of-type) {
                margin-bottom: 8px;
            }

            a {
                color: #1c78c1;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .detailsInfo {
        .tariffAndParams {
            display: flex;
            flex-direction: column;

            margin-top: 16px;

            .tariffOptions {
                display: flex;
                align-items: baseline;
            }

            .params {
                display: flex;
                align-items: baseline;
                flex-wrap: wrap;

                margin-top: 16px;

                .param {
                    padding: 2px 4px;
                    border-radius: 4px;

                    background-color: #acd6e3;

                    margin-bottom: 4px;

                    &:not(:last-of-type) {
                        margin-right: 4px;
                    }
                }
            }
        }

        .lightProfile {
            margin-top: 8px;
            margin-bottom: 16px;

            .profile {
                margin-top: 8px;
                display: flex;
                flex-direction: column;

                .profileRow {
                    span {
                        &:first-of-type {
                            font-weight: 400;
                            opacity: .5;
                        }

                        &:last-of-type {
                            font-weight: bold;
                        }
                    }

                    &:not(:last-of-type) {
                        margin-bottom: 4px;
                    }
                }
            }

            span {
                font-weight: bold;
            }
        }

        hr {
            margin-top: 20px;
            margin-bottom: 12px;

            opacity: 0;
        }
    }

    .clickForEditTip {
        position: absolute;
        top: 0;
        right: 0;

        padding: 4px;

        font-size: 8px;

        opacity: .3;
    }
}

.landingUpdate {
    display: flex;
}

.spinnerContainer {
    width: 100%;
    height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.unselectedEventContainer {
    margin-top: 32px;

    font-size: 16px;

    display: flex;
    align-items: center;

    i {
        font-size: 20px;
        margin-right: 8px;
    }
}
