.user-badges {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.lang-en {
        background: #C8E6C9;
        color: #256029;
    }

    &.lang-ru {
        background: #ECCFFF;
        color: #694382;
    }

    &.bool-true {
        background: #C8E6C9;
        color: #256029;
    }

    &.bool-2 {
        background: #C8E6C9;
        color: #256029;
    }

    &.bool-false {
        background: #ECCFFF;
        color: #694382;
    }

    &.bool-1 {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-active {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-deactivated {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-registration {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-registered {
        background: #ECCFFF;
        color: #694382;
    }

    &.role-regular {
        background: #C8E6C9;
        color: #256029;
    }

    &.role-online_admin {
        background: #FFCDD2;
        color: #C63737;
    }

    &.role-admin {
        background: #FFCDD2;
        color: #C63737;
    }

    &.role-other {
        background: #B3E5FC;
        color: #23547B;
    }

    &.category-personal {
        background: #ECCFFF;
        color: #694382;
    }

    &.category-corporate {
        background: #B3E5FC;
        color: #23547B;
    }

    &.type-0 {
        background: #B3E5FC;
        color: #23547B;
    }

    &.type-1 {
        background: #ECCFFF;
        color: #694382;
    }
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

.datatable-responsive .p-datatable-responsive-roles .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 80em) {
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

@media screen and (max-width: 40em) {
    .datatable-responsive .p-datatable.p-datatable-responsive-roles .p-datatable-thead > tr > th,
    .datatable-responsive .p-datatable.p-datatable-responsive-roles .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive-roles .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive-roles .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive .p-datatable.p-datatable-responsive-roles .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
