$min-width: 500px;
$max-width: 500px;

.createPackContainer {
    hr {
        margin-top: 2rem;
    }
}

.createOneContainer {
    hr {
        margin-top: 2rem;
    }

    .inputDataContainer {
        display: flex;
        flex-wrap: wrap;

        .commonInfo {
            min-width: $min-width;
            max-width: $max-width;

            margin-right: 2rem;
            margin-bottom: 2rem;
        }

        .profileInfo {
            min-width: $min-width;
            max-width: $max-width;
        }
    }
}

.eventsDropDown {
    min-width: $min-width;
    max-width: $max-width;
}

.inputs {
    min-width: $min-width;
    max-width: $max-width;

    label {
        min-width: 130px;
        justify-content: flex-start;

        &[aria-required="true"] {
            &:after {
                content: "*";
                color: #ff0000;
                font-weight: bold;
                margin-left: 4px;
            }
        }
    }
}

.tariffOptions {
    min-width: $min-width;
    max-width: $max-width;

    label {
        &[aria-required="true"] {
            &:after {
                content: "*";
                color: #ff0000;
                font-weight: bold;
                margin-left: 4px;
            }
        }
    }

    :global {
        .p-multiselect {
            min-width: 100%;

            margin-top: .5rem;
        }
    }
}

.additionalParams {
    min-width: $min-width;
    max-width: $max-width;

    :global {
        .p-flex-column {
            margin-top: .5rem;
        }
    }
}

